import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'Dashboard',
    isTitle: true
  },
  {
    id: 2,
    label: 'DASHBOARD',
    icon: 'uil-home-alt',
    link: '/',
  },
  {
    id: 3,
    label: 'Manage Users',
    icon: 'mdi mdi-account-cog-outline',
    subItems: [
      {
        id: 1,
        label: 'Pending or Rejected',
        link: '/manage-pending-users',
        parentId: 3
      },
      {
        id: 2,
        label: 'Active or Deactive',
        link: '/manage-users',
        parentId: 3
      }
    ]
  },
  {
    id: 4,
    label: 'Manage Notary Panel',
    icon: 'mdi mdi-view-dashboard-outline',
    subItems: [
      {
        id: 1,
        label: 'Service Types',
        link: '/manage-service-types',
        parentId: 4
      },
      {
        id: 2,
        label: 'Notary Types',
        link: '/manage-notary-types',
        parentId: 4
      },
      {
        id: 3,
        label: 'Countries',
        link: '/manage-countries',
        parentId: 4
      },
      {
        id: 4,
        label: 'States',
        link: '/manage-states',
        parentId: 4
      },
      {
        id: 5,
        label: 'Cities',
        link: '/manage-cities',
        parentId: 4
      }

    ]
  },
  {
    id: 5,
    label: 'Manage FAQs',
    icon: 'mdi mdi-comment-question-outline',
    link: 'manage-faqs'
  },
  {
    id: 6,
    label: 'Manage Packages',
    icon: 'mdi mdi-credit-card-outline',
    link: 'manage-packages'
  },
];

